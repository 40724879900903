import React from "react";
import Diamondeducation from "../components/Diamondeducation"
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import {connect} from "react-redux";
import SEO from "../components/SEO";

const CS = (props) => {
    return (
        <Layout>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <Diamondeducation {...props} fields={props?.data}/>
        </Layout>
    )
};
const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(CS);

export const query = graphql`
fragment CSFragment on MarkdownRemark {
      frontmatter {
      title
      lists{
        title
        description
        img{
            publicURL
        }
      }
    }
  }
  query CSPageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "cs-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  enCs: markdownRemark(frontmatter: {templateKey: {eq: "en-cs"}}) {
   ...CSFragment
  }
  zhCs: markdownRemark(frontmatter: {templateKey: {eq: "zh-cs"}}) {
   ...CSFragment
  }
  deCs: markdownRemark(frontmatter: {templateKey: {eq: "de-cs"}}) {
   ...CSFragment
  }
  frCs: markdownRemark(frontmatter: {templateKey: {eq: "fr-cs"}}) {
   ...CSFragment
  }
  heCs: markdownRemark(frontmatter: {templateKey: {eq: "he-cs"}}) {
   ...CSFragment
  }
  esCs: markdownRemark(frontmatter: {templateKey: {eq: "es-cs"}}) {
   ...CSFragment
  }
  itCs: markdownRemark(frontmatter: {templateKey: {eq: "it-cs"}}) {
   ...CSFragment
  }
  jaCs: markdownRemark(frontmatter: {templateKey: {eq: "ja-cs"}}) {
   ...CSFragment
  }
 }
`